<template>
  <div class="getmessage">
    <van-notice-bar scrollable van-notice-bar left-icon="volume-o">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="15000"
        :show-indicators="false"
      >
        <van-swipe-item
          >账号密码不要和其他平台(包括已关闭的)一样，更不要随意登录其他软件.
        </van-swipe-item>
        <van-swipe-item
        v-if="this.$api.IsAgent==true"
          >专属项目对比1毛钱的卡,质量会更高,每一个专属对接都对应一个卡商,更容易和卡商联系.如果某个专属不符合要求,可以换一个专属对接尝试</van-swipe-item
        >
        <van-swipe-item
          >如果没有满足要求的专属项目.可以去【缺卡需求】里发布需求.备注自己的特殊条件.你发布的需求所有的卡商都可以看到</van-swipe-item
        >
        <van-swipe-item
          >长时间不来短信，检查平台项目名和实际短信[]括号里的关键词是否一致.</van-swipe-item
        >
      </van-swipe>
    </van-notice-bar>
    <!-- 项目选择-->
    <div>
      <van-row type="flex" class="span">
        <van-col span="24">
          <van-field
            label-class="lableclass"
            readonly
            clickable
            label="项目"
            label-width="3rem"
            :value="project_value"
            placeholder="选择项目"
            @click="showPicker = true"
          >
            <template #button>
              <van-button size="small" type="primary" @click="refresh"
                >刷新</van-button
              >
            </template>
          </van-field>
          <van-popup v-model="showPicker" round position="top">
            <van-picker
              show-toolbar
              :columns="columns"
              @cancel="showPicker = false"
              @confirm="onConfirm"
            />
          </van-popup>
        </van-col>

        <van-col span="24" class="span2">
          <van-field
            label="包含的关键字"
            v-model="key"
            placeholder="输入关键字过滤自己的项目"
          >
            <template #button>
              <van-button size="small" type="primary" @click="onsave"
                >保存</van-button
              >
            </template>
          </van-field>
        </van-col>
      </van-row>
    </div>

    <!-- 运营商选择-->
    <div>
      <van-row type="flex" class="span">
        <van-col span="17">
          <van-field
            readonly
            clickable
            label-width="3rem"
            label="运营商"
            :value="operator"
            placeholder="选择运营商"
            @click="showPickerOperator = true"
          />
          <van-popup v-model="showPickerOperator" round position="top">
            <van-picker
              show-toolbar
              :columns="columnsOperator"
              @cancel="showPickerOperator = false"
              @confirm="onConfirmOperator"
            />
          </van-popup>
        </van-col>
        <van-col span="7" class="checkbox">
          <van-checkbox v-model="loop" icon-size="14px">过滤已做</van-checkbox>
        </van-col>
      </van-row>
    </div>
    <!--是否使用专属项目-->
    <div>
      <van-row type="flex" class="span">
        <van-col span="24">
          <van-checkbox
            style="margin-left: 1rem"
            v-model="isex"
            icon-size="14px"
            @change="onChange"
            >使用专属项目(从已对接专属随机取卡)</van-checkbox
          >
        </van-col>
      </van-row>
    </div>
    <!--指定号码-->
    <div class="span">
      <van-row type="flex">
        <van-field
          v-model="phoneNum"
          label-width="3.6rem"
          label="指定号码"
          placeholder="输入号码或号段前五位或省(例:四川)"
        />
      </van-row>
      <div>
        <van-row type="flex">
          <van-field
            v-model="scope_black"
            label-width="3.6rem"
            label="排除号段"
            placeholder="长度为3至7位且支持多个,用逗号分隔"
          />
        </van-row>
      </div>
    </div>
    <!--排除号段-->

    <!--按钮-->
    <div>
      <van-row type="flex" class="span">
        <van-col span="8">
          <van-button
            type="primary"
            size="small"
            @click="getPhone"
            :disabled="state"
            >获取号码</van-button
          >
        </van-col>
        <van-col span="8">
          <van-button type="warning" size="small" @click="blacklist"
            >拉黑号码</van-button
          >
        </van-col>
        <van-col span="8">
          <van-button type="info" size="small" @click="release"
            >释放号码</van-button
          >
        </van-col>
      </van-row>
    </div>
    <!--取到的号码-->
    <div class="span">
      <van-row type="flex">
        <van-col span="16">
          <van-field
            readonly
            v-model="getphoneNum"
            label="手机号码"
            label-width="3rem"
            placeholder="请先取号"
          />
        </van-col>

        <van-col span="8" class="span2">
          <van-button
            type="info"
            size="small"
            v-clipboard:copy="getphoneNum"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >复制号码</van-button
          >
        </van-col>
      </van-row>
      <van-tag v-if="last_sms !== ''" type="primary" plain
        >此卡上次来短信时间:{{ last_sms }}</van-tag
      >
      <van-tag type="warning" v-if="c_operator !== ''" plain>{{
        c_operator
      }}</van-tag>
    </div>

    <!--获取到的短信code-->
    <div class="span">
      <van-row type="flex">
        <van-col span="16" class="span2">
          <van-field
            rows="1"
            autosize
            readonly
            type="textarea"
            v-model="messageCode"
            label="验证码"
            label-width="3rem"
            placeholder="等待获取验证码"
          />
        </van-col>

        <van-col span="8" class="span2">
          <van-button
            type="info"
            size="small"
            v-clipboard:copy="messageCode"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >复制Code</van-button
          >
        </van-col>
      </van-row>
      <!--获取到的短信内容-->
      <div>
        <van-row type="flex" span="24">
          <van-col span="16">
            <van-field
              rows="1"
              autosize
              readonly
              type="textarea"
              v-model="messageText"
              label="短信内容"
              label-width="3rem"
              :placeholder="placeholder"
            />
          </van-col>
          <van-col span="8" class="span2">
            <van-button
              type="info"
              size="small"
              v-clipboard:copy="messageText"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              >复制内容</van-button
            >
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //项目关键字
      key: "",
      //  定时器
      timer: "",
      // 定时器执行计次
      count: 0,
      //  获取短信的按钮状态
      state: false,
      //  短信内容框的提示文本
      placeholder: "等待获取短信",
      //  过滤已做
      loop: true,
      //  短信内容
      messageText: "",
      //  验证码内容
      messageCode: "",
      //  指定的号码
      phoneNum: "",
      //  获取到的手机号码
      getphoneNum: "",
      //  取号要用到的 参数
      project_id: "",
      project_type: "",
      //  刷新项目 获取到的全部项目
      project_all: [],
      //上次短信时间：
      last_sms: "",
      //排除号段
      scope_black: "",
      //  项目选择的参数
      project_value: "",
      showPicker: false,
      columns: [],
      columns_back: [],
      project_index: 0,
      //  运营商选择的参数
      operator: "默认",
      showPickerOperator: false,
      columnsOperator: [
        "无限制",
        "中国移动",
        "中国联通",
        "中国电信",
        "实卡",
        "虚卡",
      ],
      Operatorindex: 0,
      isex: false,
      get_message_type: "1",
      c_operator: "",
    };
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  mounted() {
    this.key = localStorage.getItem("filter");
    if (!this.key) {
      this.key = "";
    }
    this.refresh();
  },
  methods: {
    onChange() {
      this.filter();
    },
    onConfirm(value) {
      this.project_value = value;
      this.showPicker = false;

      if (value.indexOf("普通项目") !== -1) {
        //普通项目
        for (let i = 0; i < this.project_all.length; i++) {
          if (
            this.project_all[i].project_type == "1" &&
            value.indexOf("普通项目" + this.project_all[i].id) !== -1
          ) {
            this.project_index = i;
            break;
          }
        }
      }

      if (value.indexOf("专属项目") !== -1) {
        //普通项目
        for (let i = 0; i < this.project_all.length; i++) {
          if (
            this.project_all[i].project_type == "2" &&
            value.indexOf("专属项目" + this.project_all[i].key_) !== -1
          ) {
            this.project_index = i;
            break;
          }
        }
      }
    },
    onsave() {
      localStorage.setItem("filter", this.key);
      this.filter();
    },
    filter() {
      if (!this.key || this.key == "") {
        this.columns.length = 0;
        for (let i = 0; i < this.columns_back.length; i++) {
          if (this.isex) {
            if (this.columns_back[i].indexOf("普通项目") != -1) {
              this.columns.push(this.columns_back[i]);
            }
          } else {
            this.columns.push(this.columns_back[i]);
          }
        }
        this.onConfirm(this.columns[0]);
        return;
      }
      this.columns.length = 0;
      for (let i = 0; i < this.columns_back.length; i++) {
        if (this.columns_back[i].indexOf(this.key) != -1) {
          if (this.isex) {
            if (this.columns_back[i].indexOf("普通项目") != -1) {
              this.columns.push(this.columns_back[i]);
            }
          } else {
            this.columns.push(this.columns_back[i]);
          }
        }
      }
      this.onConfirm(this.columns[0]);
    },
    onConfirmOperator(value, index) {
      this.operator = value;
      this.showPickerOperator = false;
      this.Operatorindex = index;
    },
    //  复制的方法
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    },
    //  刷新项目列表
    async refresh() {
      let addition =
        (parseFloat(localStorage.getItem("agent_addition")) + 100) / 100;
      if (isNaN(addition)) {
        addition = 1;
      }
      //  获取收藏项目
      this.columns.length = 0;
      this.project_all.length = 0;
      let res = await this.$api.fn_user_project_get();
      let ret = res.data;
      if (typeof ret == "string") {
        ret = ret.replace("\\/g", "");
        ret = eval("(" + ret + ")");
      }
      res = ret.data;
      for (let i = 0; i < res.length; i++) {
        res[i].project_type = "1";
        this.project_all.push(res[i]);
        this.columns.push(
          "[普通项目" +
            res[i].id +
            "]￥" +
            parseFloat((res[i].price * addition).toFixed(4)) +
            "-" +
            res[i].name
        );
      }
      res = await this.$api.fn_user_exclusive_get();
      ret = res.data;
      if (typeof ret == "string") {
        ret = ret.replace("\\/g", "");
        ret = eval("(" + ret + ")");
      }
      res = ret.data;
      for (let i = 0; i < res.length; i++) {
        if (res[i].state < 2 && res[i].state2 == 1) {
          continue;
        }
        res[i].project_type = "2";
        res[i].id = res[i].key_;
        this.project_all.push(res[i]);
        this.columns.push(
          "[专属项目" +
            res[i].id +
            "]￥" +
            parseFloat((res[i].price * addition).toFixed(4)) +
            "-" +
            res[i].name
        );
      }
      if (this.columns.length > 0) {
        this.onConfirm(this.columns[0]);
        this.columns_back.length = 0;
        this.columns.forEach((element) => {
          this.columns_back.push(element);
        });
        this.$toast("刷新成功");
        this.filter();
      } else {
        if (ret.message.indexOf( "过期") !=-1|| ret.message.indexOf( "密码错误") !=-1) {
          this.$toast("身份认证过期,请重新登录");
        } else {
          this.$toast("请去专属或者项目列表中添加项目");
        }
      }
    },
    //  拉黑
    async blacklist() {
      if (
        this.getphoneNum.length === 0 ||
        this.project_id.length === 0 ||
        this.project_type.length === 0
      ) {
        this.$toast("已经拉黑过");
        return;
      }
      let ret = await this.$api.fn_add_blacklist(
        this.getphoneNum,
        this.project_id,
        this.project_type
      );
      ret = ret.data;
      if (ret.message) {
        if (ret.message === "拉黑成功") {
          this.state = false;
          this.count = 0;
          this.$toast("拉黑并释放成功");
          this.state = false;
          this.count = 0;
          clearInterval(this.timer);
        } else {
          this.$toast(ret.message);
        }
      }
    },
    //  释放手机号
    async release() {
      if (
        this.getphoneNum.length === 0 ||
        this.project_id.length === 0 ||
        this.project_type.length === 0
      ) {
        this.$toast("已经释放过");
        return;
      }
      await this.$api.fn_free_mobile(
        this.getphoneNum,
        this.project_id,
        this.project_type
      );
      this.state = false;
      this.count = 0;
      clearInterval(this.timer);
      this.$toast("释放成功");
    },
    //  获取手机号
    async getPhone() {
      let ret = "";
      //  判断选择的项目 项目不对 直接返回
      if (
        this.project_index === this.columns.length &&
        this.columns.length === 0
      ) {
        this.$toast("请选择项目");
        return 0;
      }
      let temp_type = this.project_all[this.project_index].project_type;
      this.get_message_type = this.project_all[this.project_index].project_type;
      if (this.isex) {
        if (this.project_all[this.project_index].project_type == 2) {
          this.$toast("已经是专属项目取卡,请不要勾选[使用专属项目]");
          return 0;
        }
        temp_type = "3";
        this.get_message_type = "2";
      }
      ret = await this.$api.fn_get_mobile(
        // 取卡请求
        this.project_all[this.project_index].id,
        temp_type,
        this.Operatorindex,
        this.loop,
        this.phoneNum,
        this.scope_black
      );
      ret = ret.data;
      if (ret.message) {
        if (ret.message === "ok") {
          this.messageCode = "";
          this.messageText = "";
          this.project_id = this.project_all[this.project_index].id;
          this.project_type = temp_type;
          this.timer = setInterval(this.getMessage, 1000);
          this.state = true;
          this.getphoneNum = ret.mobile;
          const data = new Date(
            parseInt(ret["上次短信时间:"]) * 1000 - 3600000 * 8
          );
          this.c_operator = ret.operator;
          this.last_sms = data.toLocaleString();
          if (temp_type != 1) {
            this.$toast("从专属项目获取成功");
          } else {
            this.$toast("从普通项目获取成功");
          }
        } else {
          if (ret.message) {
            if (ret.message.indexOf("对接码是[") !== -1) {
              const str = ret.message.substring(
                ret.message.indexOf("[") + 1,
                ret.message.indexOf("]")
              );
              this.copy(str);
              this.$toast(
                ret.message +
                  "\n\n对接码已经复制到剪辑版,如需要 请使用[对接码对接专属]对接此专属"
              );
            } else {
              this.$toast(ret.message);
            }
          }
        }
      }
    },
    copy(text) {
      this.$copyText(text).then(
        function () {},
        function () {}
      );
    },
    //  获取短信
    async getMessage() {
      if (this.count >= 300) {
        this.release();
        return;
      }
      this.count = this.count + 1;

      // 获取短信
      if (this.count % 3 == 0) {
        let ret = await this.$api.fn_get_message(
          this.getphoneNum,
          this.project_id,
          this.get_message_type
        );
        let ret2 = ret.data;
        if (typeof ret2 == "string") {
          ret2 = ret2.replace("\\/g", "");
          ret2 = eval("(" + ret2 + ")");
        }
        ret = ret2.data;
        if (ret.length !== 0) {
          this.messageText = ret[0].modle;
          this.messageCode = ret2.code;
          this.copy(this.messageCode);
          this.release();
          return;
        }
      }

      this.placeholder = "正在获取,剩:" + (300 - this.count) + "s";
    },
  },
};
</script>

<style scoped>
.getmessage {
  width: 100%-40px;
  height: 100%-40px;
  border: 1px solid #464646;
  padding-bottom: 50px;
}
.span {
  font-size: 16px;
  text-align: center;
  border: 1px solid #464646;
  margin: 3%;
  padding-top: 3px;
  padding-bottom: 3px;
}
.span2 {
  padding-top: 8px;
  padding-bottom: 3px;
}
.checkbox {
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 3px;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
}
</style>
